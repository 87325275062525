.mainContainer {
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
}

.headerContainer {
    background-color: #FA383E;
    padding-left: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

.searchContainer {
    display: flex;
    width: 500px;
    margin-right: 2em;
}

.logoImage {
    height: 100px;
}

.searchIcon {
    color: #FA383E;
}

.loadingContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .searchContainer {
        display: none;
    }
}

.productButton:hover {
    opacity: 0.5;
}

.productList {
    display: grid;
    gap: 1rem;
    justify-content: center;
    margin-top: 40px;
    grid-template-columns: repeat(3, 1fr);
}
  
  
@media only screen and (max-width: 815px) {
    .productList {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
  

@media only screen and (max-width: 580px) {
    .productList {
        grid-template-columns: 1fr !important;
    }
}

.title {
    margin: 0;
}

@media (max-width: 767px) {

    .title {
        font-size: 25px !important;
    }

    .rotatingLogo {
        height: 60px !important;
    }
}