.productPageMainContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.headerContainer {
    background-color: #FA383E;
    padding-left: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

.logoImage {
    height: 100px;
}

.productContainer {
    margin-top: 5em;
    background-color: white;
    margin: 4em 10%;
    border-radius: 0.25rem;
}

.productSubContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.productImage {
    width: 290px;
    height: 290px;
    padding: 5px 0;
}

.productInternalContainer {
    display: flex;
    padding: 1.5em;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.productInternalLeftContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productInternalRightContainer {
    padding-left: 2.5em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    padding-top: 1em;
}

.buttonProduct {
    height: 50px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    border: none !important;
    outline: none !important;
    border-radius: 30px !important;
    margin-top: 10px;
    padding: 0 25px !important;
    font-family: 'Exo 2' !important;
}

.loadingContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.errorContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    padding: 1em;
}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.productPricePrefix {
    color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0 !important;
    padding-right: 0.2rem;
}

.productPrice {
    color: #F81219 !important;
    font-weight: 600;
    font-family: 'Exo 2';
    font-size: 24px;
    margin: 0 !important;
    text-align: center !important;
}

.productTitle {
    text-align: center;
    font-family: 'Exo 2';
    font-weight: bold;
    margin-bottom: 10px !important;
    font-size: 18px !important;
}

@media only screen and (max-width: 1000px) {
    .productImage {
        border-right: 0;
    }

    .productContainer {
        margin: 1em;
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .productTitle {
        font-size: 18px !important;
    }

    .productPrice {
        font-size: 24px !important;
    }

    .priceContainer {
        margin-top: 0.5em;
    }
}

@media only screen and (max-width: 400px) {
    .productImage {
        width: 250px;
        height: 250px;
    }
}